export * from "ce/hooks/datasourceEditorHooks";
import type { HeaderActionProps } from "ce/hooks/datasourceEditorHooks";
import { useHeaderActions as useHeaderActionsCE } from "ce/hooks/datasourceEditorHooks";
import React from "react";
import type { Datasource } from "entities/Datasource";
import { useFeatureFlag } from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAG } from "ee/entities/FeatureFlag";
import { getHasCreateDatasourceActionPermission } from "ee/utils/BusinessFeatures/permissionPageHelpers";
import NewReusableActionButton from "ee/pages/Editor/PackageEditor/DataSourceEditor/NewReusableActionButton";
import NewActionButton from "ee/pages/Editor/WorkflowEditor/DataSourceEditor/NewActionButton";
import { IDE_TYPE, type IDEType } from "ee/IDE/Interfaces/IDETypes";

export const useHeaderActions = (
  ideType: IDEType,
  {
    datasource,
    isPluginAuthorized,
    pluginType,
    showReconnectButton,
  }: HeaderActionProps,
) => {
  const headerActions = useHeaderActionsCE(ideType, {
    datasource,
    isPluginAuthorized,
    pluginType,
    showReconnectButton,
  });
  const isFeatureEnabled = useFeatureFlag(FEATURE_FLAG.license_gac_enabled);
  const canCreateDatasourceActions = getHasCreateDatasourceActionPermission(
    isFeatureEnabled,
    datasource?.userPermissions ?? [],
  );

  if (ideType === IDE_TYPE.App) {
    return headerActions;
  } else if (ideType === IDE_TYPE.Package) {
    const newActionButton = (
      <NewReusableActionButton
        datasource={datasource as Datasource}
        disabled={!canCreateDatasourceActions || !isPluginAuthorized}
        eventFrom="datasource-pane"
        pluginType={pluginType}
      />
    );

    return {
      newActionButton,
      generatePageButton: null,
    };
  } else if (ideType === IDE_TYPE.Workflow) {
    const newActionButton = (
      <NewActionButton
        datasource={datasource as Datasource}
        disabled={!canCreateDatasourceActions || !isPluginAuthorized}
        eventFrom="datasource-pane"
        pluginType={pluginType}
      />
    );

    return {
      newActionButton,
      generatePageButton: null,
    };
  }

  return {};
};
